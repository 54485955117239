.navbarcall{
    background-color: #a35e0a; /* Green */
    border: none;
    color: white;
    padding: 7px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 5px;
  }

  .backcolor{
    background-color: #b6ced7;
  }